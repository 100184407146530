import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import { api } from '~/utils/gql-client.server.ts'
import { useFetcher, useLoaderData } from '@remix-run/react'
import { blogPostsNormalize } from '~/utils/blog.ts'
import { useTranslation } from 'react-i18next'
import type { BlogPostsListItemFragment } from '~/model/api.ts'
import * as React from 'react'
import { useEffect, useState } from 'react'
import type { Article } from '~/components/articles.tsx'
import { Articles } from '~/components/articles.tsx'
import z from 'zod'
import { zx } from 'zodix'
import { Cols } from '~/components/cols.tsx'
import { Icon } from '~/components/icon.tsx'
import { ArticleHeader } from '~/components/article-header.tsx'
import { BlogCategories } from '~/components/blog-categories.tsx'
import { cn, useDebounce } from '~/utils/misc.ts'
import { Empty } from '~/components/empty.tsx'
import { ActionButton } from '~/components/action-button.tsx'
import { postsPerPage } from '~/routes/resources.blog-posts.ts'
import { generateMeta, type MetaProps } from '~/utils/meta.ts'
import { getPageState } from '~/utils/page-state.ts'
import { fallbackLng, supportedLngs } from '~/utils/i18n.ts'
import i18next from '~/utils/i18next.server.ts'
import { GradientsContainer } from '~/components/gradients-container.tsx'
import type { InputProps } from '~/components/input.tsx'
import { Spinner } from '~/components/spinner.tsx'
import { Gradient } from '~/components/gradients.tsx'

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return generateMeta(data?.metaProps as MetaProps)
}

export let handle = {
  i18n: 'blog',
}

export async function loader({ request }: LoaderFunctionArgs) {
  const { locale, publicationState } = await getPageState(request)

  const { q, start } = zx.parseQuery(request, {
    start: zx.NumAsString.optional(),
    q: z.string().optional(),
  })

  const { blogCategories } = await api.BlogCategories()

  let blogPosts: BlogPostsListItemFragment[] = []
  let blogsPostsFromSearch = false

  if (q) {
    const blogPostsSearch = await api.BlogPostsSearch({
      sort: 'publishDate:desc',
      start: 0,
      where: {},
      locale: locale ?? fallbackLng,
      searchTerm: q,
      publicationState: publicationState,
      limit: 1000,
    })
    blogPosts = blogPostsSearch.blogPostsSearch
    blogsPostsFromSearch = true
  } else {
    const blogPostsResult = await api.BlogPosts({
      sort: 'publishDate:desc',
      start: start ?? 0,
      limit: postsPerPage,
      publicationState: publicationState,
      locale: locale ?? fallbackLng,
    })

    blogPosts = blogPostsResult.blogPosts
  }

  const t = await i18next.getFixedT(locale, 'blog')
  const metaProps: MetaProps = {
    seo: {
      metaTitle: t('blog_meta_title'),
      metaDescription: t('blog_meta_description'),
    },
    localizations: supportedLngs,
    pageState: await getPageState(request),
  }

  return json({
    metaProps: metaProps,
    q,
    blogPosts: await blogPostsNormalize(request, blogPosts),
    blogCategories,
    blogsPostsFromSearch,
  })
}

const SearchBlog = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { t } = useTranslation(['blog'])
    const fetcher = useFetcher<typeof loader>({ key: 'search-fetcher' })
    const submit = useDebounce(fetcher.submit, 400)

    return (
      <div className="relative mt-6">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-6">
          <Icon name="search" className="h-8 w-8 text-icon-muted" />
        </div>
        <fetcher.Form
          id="search-form"
          role="search"
          onChange={event => submit(event.currentTarget)}
        >
          <input
            id="q"
            name="q"
            ref={ref}
            placeholder={t('search_blogposts_placeholder')}
            defaultValue={''}
            className="block w-full bg-input-background-default py-6 pl-16 pr-6 text-body-20 text-global-white outline-0 ring-1 ring-border-secondary placeholder:text-input-text-disabled focus:outline-none focus:ring-2 focus:ring-border-focus"
            type="search"
            {...props}
          />
        </fetcher.Form>
      </div>
    )
  },
)

SearchBlog.displayName = 'SearchBlog'

export default function BlogIndex() {
  const { t } = useTranslation(['blog'])
  const { blogPosts, blogCategories, blogsPostsFromSearch } =
    useLoaderData<typeof loader>()
  const [posts, setPosts] = useState(blogPosts)
  const [loading, setLoading] = useState(false)
  const [endReached, setEndReached] = useState(false)
  const fetcher = useFetcher<typeof loader>()

  const [searchPosts, setSearchPosts] = useState([] as typeof blogPosts)
  const searchFetcher = useFetcher<typeof loader>({ key: 'search-fetcher' })
  const [searchLoading, setSearchLoading] = useState(false)
  const [searching, setSearching] = useState(false)

  useEffect(() => {
    if (!fetcher.data || fetcher.state === 'loading') {
      return
    }

    if (fetcher.data) {
      const newPosts = fetcher.data.blogPosts
      setLoading(false)

      if (!newPosts || newPosts.length === 0) {
        setEndReached(true)
        return
      }
      setPosts(prevPosts => [...prevPosts, ...newPosts])
    }
  }, [blogPosts, fetcher.data, fetcher.state])

  useEffect(() => {
    if (searchFetcher.state === 'loading' || searchFetcher.data) {
      setSearchLoading(searchFetcher.state === 'loading')
    }

    if (searchFetcher.data) {
      setSearchPosts(searchFetcher.data.blogPosts as typeof blogPosts)
    }
  }, [searchFetcher])

  function loadMore() {
    setLoading(true)
    const start = posts.length
    const query = `?index&start=${start}`
    fetcher.load(query)
  }

  function handleStopSearching(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement>,
  ) {
    if (!event.currentTarget.value || event.currentTarget.value === '') {
      setSearchPosts([])
      setSearching(false)
      setSearchLoading(false)
    }
  }

  return (
    <>
      <Gradient
        className={cn(
          searching ? 'opacity-100' : 'opacity-0',
          'transition-opacity duration-500',
        )}
      />
      <ArticleHeader title={t('blog')} showBg={!searching}>
        <SearchBlog
          onFocus={() => setSearching(true)}
          onInput={() => setSearching(true)}
          onBlur={handleStopSearching}
          onChange={handleStopSearching}
        />
      </ArticleHeader>

      <GradientsContainer>
        <div className="container">
          <Cols size="10">
            {searching ? (
              <div className="min-h-screen">
                {searchLoading && (
                  <div className="flex w-full justify-center pt-20 md:pt-40">
                    <Spinner size="medium" />
                  </div>
                )}
                {!searchLoading && searchPosts.length > 0 && (
                  <div className="pb-16">
                    <Articles
                      isMainArticle={false}
                      articles={searchPosts as Article[]}
                    />
                  </div>
                )}
              </div>
            ) : (
              <>
                <BlogCategories
                  blogCategories={blogCategories.map(category => ({
                    link: `/blog/category/${category.slug}`,
                    title: category.title,
                  }))}
                />
                {posts.length === 0 && (
                  <Empty message={t('blog_posts_empty')} />
                )}
                {posts.length > 0 && (
                  <div className="pb-16">
                    <Articles
                      isMainArticle={!blogsPostsFromSearch}
                      articles={posts as Article[]}
                    />
                    <div className="pt-16 text-center">
                      {!endReached && (
                        <ActionButton
                          size="big"
                          onClick={() => loadMore()}
                          state={loading ? 'loading' : undefined}
                        >
                          {t('load_more_button')}
                        </ActionButton>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </Cols>
        </div>
      </GradientsContainer>
    </>
  )
}
